import React from 'react';
import ReactDOM from 'react-dom';

const App = () => {
	return <h1>Hello from React in Rails with Vite!!!</h1>;
};

document.addEventListener('DOMContentLoaded', () => {
	const rootElement = document.getElementById('root');
	if (rootElement) {
		const root = ReactDOM.createRoot(rootElement);
		root.render(<App />);
	}
});
